import React from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "../root/contents/prismic/preview"
import { PrismicPage } from "../root/contents/prismic/pages/PrismicPage"
import { makeStyles } from "@material-ui/core"
import { whenMobile } from "../root/ui/mui"
import DefaultLayout from "../layouts/DefaultLayout"
import CustomContainer from "../ui/molecules/CustomContainer"
import { FadeIn } from "../ui/animations"
import { RawHtml } from "../root/contents/prismic/richText/serializer"

interface Props {
  data: any
}

const useStyles = makeStyles((theme) => ({
  title: {
    margingTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),

    "& h1": {
      fontSize: theme.typography.h3.fontSize,
      lineHeight: theme.typography.h3.lineHeight,
      marginTop: theme.spacing(4),
    },
    "& h2": {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      marginTop: theme.spacing(4),
    },
    "& h3": {
      fontSize: theme.typography.h5.fontSize,
      lineHeight: theme.typography.h5.lineHeight,
    },
    "& h4": {
      fontSize: theme.typography.h5.fontSize,
      lineHeight: theme.typography.h5.lineHeight,
    },
    "& h5": {
      fontSize: theme.typography.h5.fontSize,
      lineHeight: theme.typography.h5.lineHeight,
    },
    "& h6": {
      fontSize: theme.typography.h6.fontSize,
      lineHeight: theme.typography.h6.lineHeight,
    },
    "& p": {
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
      marginTop: 0,
      marginBottom: 0,
    },

    [whenMobile(theme)]: {
      "& h1": {
        fontSize: theme.typography.h4.fontSize,
        lineHeight: theme.typography.h4.lineHeight,
      },
      "& h2": {
        fontSize: theme.typography.h5.fontSize,
        lineHeight: theme.typography.h5.lineHeight,
      },
      "& h3": {
        fontSize: theme.typography.h6.fontSize,
        lineHeight: theme.typography.h6.lineHeight,
      },
      "& h4": {
        fontSize: theme.typography.h6.fontSize,
        lineHeight: theme.typography.h6.lineHeight,
      },
      "& h5": {
        fontSize: theme.typography.subtitle1.fontSize,
        lineHeight: theme.typography.subtitle1.lineHeight,
      },
      "& h6": {
        fontSize: theme.typography.subtitle1.fontSize,
        lineHeight: theme.typography.subtitle1.lineHeight,
      },
    },
  },
}))

const IubendaPage = ({ data }: Props) => {
  const classes = useStyles()
  return (
    <PrismicPage page={data.prismicIubendaPage}>
      <DefaultLayout>
        <CustomContainer className={classes.container}>
          <FadeIn>
            {/* <RichTextField
              value={data.prismicIubendaPage?.data?.title}
              variant="h1"
              className={classes.title}
            /> */}
            <RawHtml value={data.prismicIubendaPage?.data?.policy_body ?? ""} />
          </FadeIn>
        </CustomContainer>
      </DefaultLayout>
    </PrismicPage>
  )
}

export default withPrismicPreview(IubendaPage)

export const pageQuery = graphql`
  query IubendaPageQuery($id: ID!) {
    prismicIubendaPage(prismicId: { eq: $id }) {
      prismicId
      lang
      data {
        title {
          text
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
        policy_body
      }
    }
  }
`
